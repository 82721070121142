import React, { Fragment } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import parse from 'parse-url'

import Header from '../components/Header'
import Auth from '../components/Auth'
import useAuth from '../hooks/useAuth'
import '../styles/invite.css'

import defaultUser from '../images/user-invite.png'

import { GET_INVITE, CONFIRM_INVITE } from '../gql/invite'

const meta = ({ href, origin }) =>  (
  <Helmet>
    <title>You are invited to Regym</title>
    <meta name="title" content="You are invited to Regym" />
    <meta name="description" content="Just open the link and start improve your body" />

    <meta property="og:type" content="website" />
    <meta property="og:url" content={href} />
    <meta property="og:title" content="You are invited to Regym" />
    <meta property="og:description" content="Just open the link and start improve your body" />
    <meta property="og:image" content="https://regym.app/promotion.png" />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content={href} />
    <meta property="twitter:title" content="You are invited to Regym" />
    <meta property="twitter:description" content="Just open the link and start improve your body" />
    <meta property="twitter:image" content="https://regym.app/promotion.png" />
  </Helmet>
)

export default ({ location, navigate }) => {
  const { query } = parse(location.href || 'https://get')
  const token = query.token
  if (!token) return meta(location)

  console.log(location)

  const isAuthorized = useAuth()
  const { data, loading, error } = useQuery(GET_INVITE, {
    variables: { token }
  })

  const [confirmInvite] = useMutation(CONFIRM_INVITE, {
    variables: { token },
    onCompleted: (data) => {
      try {
        navigate('/dashboard?confirmInvite=true', { replace: true })
      } catch (e) {
        // ToDo: Show message about error
      }
    }
  })

  if (loading) return meta(location)
  if (!data) return meta(location)

  const image = get(data, 'invite.inviter.avatar', '')
  const name = get(data, 'invite.inviter.name', '')
  const description = get(data, 'invite.inviter.tagLine', '')
  const isExpired = error || get(data, 'invite.status') === 'confirmed'

  const renderDescription = () => {
    if (isExpired) {
       return (
         <p className="invite-ask">
           Invite incorrect
         </p>
       )
    }
    if (isAuthorized) {
      return (
        <Fragment>
          <p className="invite-ask">
            Do you confirm that you accept the request to become your coach from Superhero Coach ?
          </p>
          <p className="invite-tip">
            You consent to sharing your data (like training, progress, and goals) with your trainer
          </p>
        </Fragment>
      )
    }
    return (
      <p className="invite-tip">
        You should login by Facebook or Apple for confirm invite
      </p>
    )
  }

  const renderButton = () => {
    if (isExpired) {
      return (
        <button onClick={() => navigate('/dashboard', { replace: true })} className="invite-button">
          Go to Dashboard
        </button>
      )
    }
    if (isAuthorized) {
      return (
        <button onClick={() => confirmInvite()} className="invite-button">
          Accept Invite
        </button>
      )
    }
    return <Auth />
  }

  return (
    <div id="invite">
      {meta(location)}
      <Header />
      <div className="invite-content">
        <img src={isExpired ? defaultUser : image} alt="Inviter avatar" className="invite-image" />
        <p className="invite-name">{isExpired ? '----------------' : name}</p>
        <p className="invite-description">{isExpired ? '-------------' : description}</p>
        {renderDescription()}
        <div className="button-block">
          {renderButton()}
        </div>
      </div>
    </div>
  )
}
